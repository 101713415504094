import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';
import React from 'react';
import Layout from '@/components/common/Layout/Layout';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getProcedures, getContentPrivateApi, getPageData, getPagePreview } from '@/lib/api';
import useTranslation from '@/hooks/useTranslation';
import { BasePageProps, INewsCard } from '@/interfaces';
import Lead, { LeadType } from '@/components/blocks/Lead/Lead';
import { Card1Props } from '@/components/blocks/Card1/Card1';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import Spacer from '@/components/common/Spacer/Spacer';
import { getOurCustomersFullData } from '@/helpers/data/getOurCustomersFullData';
import { getAxiosPromiseData } from '@/helpers/data/getAxiosPromiseData';
import { useHideComponents } from '@/hooks/useHideComponents';
import { getLoggingHeaders } from '@/helpers/api/getLoggingHeaders';
import WithTabs, { WithTabsProps } from '@/componentsNew/sections/WithTabs/WithTabs';
import CardGrid from '@/componentsNew/blocks/CardGrid/CardGrid';
import WhyChooseSection from '@/componentsNew/sections/WhyChooseSection/WhyChooseSection';
import SupportSection from '@/componentsNew/sections/SupportSection/SupportSection';
import BannersSection from '@/componentsNew/sections/BannersSection/BannersSection';

const CustomersSliderSection = dynamic(() => import('@/components/sections/CustomersSliderSection/CustomersSliderSection'));
const NewsSection = dynamic(() => import('@/componentsNew/sections/NewsSection/NewsSection'));

interface PageMainData {
    lead?: LeadType;
}
interface IndexProps extends BasePageProps {
    videoSliderItems: Card1Props[];
}

const yandexVerification = '75501044106e84fd';

const Index: React.FC<IndexProps & InferGetServerSidePropsType<typeof getServerSideProps>> = ({ meta, lead, newsCards, ourCustomers }) => {
    const { t } = useTranslation('index');
    const isShownComponents = useHideComponents();

    const forSuppliers: WithTabsProps = t('forSuppliers', { returnObjects: true });
    const ContentTabsForSuppliers = forSuppliers?.content?.map((content: any, index: number) => (
        <CardGrid key={index} cards={content} tabIndex={index} indexCardWithMetrika={index === 2 ? [0] : undefined} />
    ));
    const ContentTabsNews = newsCards.map((content, index) => <NewsSection key={index} cards={content} tabIndex={index} />);

    return (
        <Layout meta={meta} isDarkHeader={true} isInner={false}>
            <Head>
                <meta name="yandex-verification" content={yandexVerification} key="yandex-verification" />
            </Head>

            <Spacer bottomOffset="main">
                {lead && (
                    <Lead {...lead} metriksSampleSlides={{ event: 'main-click-stories', category: 'main', action: 'click-stories' }} />
                )}
            </Spacer>

            <Spacer topOffset="main" bottomOffset="main">
                <WithTabs
                    {...t('forSuppliers', { returnObjects: true })}
                    content={ContentTabsForSuppliers}
                    metriksSampleTabs={{ event: 'suppliers-block-click-category', category: 'main', action: 'click-category' }}
                />
            </Spacer>

            <Spacer topOffset="main" bottomOffset="main">
                {isShownComponents && (
                    <WhyChooseSection
                        {...t('whyChooseSection', { returnObjects: true })}
                        metriksSampleLink={{ event: 'main-click-raex', category: 'main', action: '' }}
                    />
                )}
            </Spacer>

            <Spacer topOffset="main" bottomOffset="main">
                {!!ourCustomers?.length && isShownComponents && (
                    <CustomersSliderSection
                        metriksSample={{ event: 'list-customers-click', category: 'customers', action: 'click-block' }}
                        content={{
                            ...t('ourCustomers', { returnObjects: true }),
                            items: ourCustomers as any
                        }}
                    />
                )}
            </Spacer>

            <Spacer topOffset="main" bottomOffset="main">
                {isShownComponents && <BannersSection {...t('bannersSection', { returnObjects: true })} />}
            </Spacer>

            <Spacer topOffset="main" bottomOffset="main">
                {isShownComponents && (
                    <WithTabs
                        {...t('news', { returnObjects: true })}
                        content={ContentTabsNews}
                        metriksSample={{ event: 'main-click-news', category: 'main', action: 'click-news' }}
                        metriksSampleTabs={{ event: 'press-click-filter', category: 'main', action: 'click-button' }}
                        headerInRow={true}
                    />
                )}
            </Spacer>

            <Spacer topOffset="main" bottomOffset="main">
                {isShownComponents && <SupportSection {...t('supportSection', { returnObjects: true })} />}
            </Spacer>
        </Layout>
    );
};

export const getServerSideProps = async ({ req, query, locale = 'ru' }: GetServerSidePropsContext) => {
    const newsPressCardsPromise = getContentPrivateApi('/news', {
        params: {
            locale: `${locale}`,
            type: 'press_center',
            limit: 4
        },
        headers: getLoggingHeaders(req)
    });
    const newsMediaCardsPromise = getContentPrivateApi('/news', {
        params: {
            locale: `${locale}`,
            type: 'media',
            limit: 4
        },
        headers: getLoggingHeaders(req)
    });
    const newsImportantCardsPromise = getContentPrivateApi('/news', {
        params: {
            locale: `${locale}`,
            type: 'imp_messages',
            limit: 4
        },
        headers: getLoggingHeaders(req)
    });
    const newsAnnounceCardsPromise = getContentPrivateApi('/news', {
        params: {
            locale: `${locale}`,
            type: 'proc_announce',
            limit: 4
        },
        headers: getLoggingHeaders(req)
    });

    const proceduresDataPromise = getProcedures({ headers: getLoggingHeaders(req) });

    let proceduresData = null;
    let newsPressCards: INewsCard[] | null = null;
    let newsMediaCards: INewsCard[] | null = null;
    let newsImportantCards: INewsCard[] | null = null;
    let newsAnnounceCards: INewsCard[] | null = null;

    const ourCustomers = await getOurCustomersFullData({ req, locale, headers: getLoggingHeaders(req) });

    try {
        const { data } = await proceduresDataPromise;

        if (data) proceduresData = data;
    } catch (err) {
        console.log(err);
    }

    try {
        const { data } = await newsPressCardsPromise;

        if (data.items) {
            data.items.forEach((item: INewsCard) => {
                item.typeId = 'news-press';
            });

            newsPressCards = data.items;
        }
    } catch (err) {
        console.log(err);
    }

    try {
        const { data } = await newsMediaCardsPromise;

        if (data?.items) {
            data.items.forEach((item: INewsCard) => {
                item.typeId = 'news-media';
            });

            newsMediaCards = data.items;
        }
    } catch (err) {
        console.log(err);
    }

    try {
        const { data } = await newsImportantCardsPromise;

        if (data?.items) {
            data.items.forEach((item: INewsCard) => {
                item.typeId = 'news-important';
            });

            newsImportantCards = data.items;
        }
    } catch (err) {
        console.log(err);
    }

    try {
        const { data } = await newsAnnounceCardsPromise;

        if (data?.items) {
            data.items.forEach((item: INewsCard) => {
                item.typeId = 'news-announce';
            });

            newsAnnounceCards = data.items;
        }
    } catch (err) {
        console.log(err);
    }

    const newsCards = [newsPressCards, newsMediaCards, newsImportantCards, newsAnnounceCards];

    let pageData = null;
    const hash = query?.hash;

    if (hash) {
        pageData = await getAxiosPromiseData(
            getPagePreview<PageMainData>(hash, {
                params: { locale },
                headers: getLoggingHeaders(req)
            })
        );

        if (!pageData) {
            pageData = await getAxiosPromiseData(
                getPageData<PageMainData>('main', {
                    params: { locale },
                    headers: getLoggingHeaders(req)
                })
            );
        }
    } else {
        pageData = await getAxiosPromiseData(
            getPageData<PageMainData>('main', {
                params: { locale },
                headers: getLoggingHeaders(req)
            })
        );
    }

    return {
        props: {
            meta: {
                title:
                    locale === 'ru'
                        ? 'ЭТП ТЭК-Торг - электронная торговая площадка для участия в закупках, тендерах и аукционах'
                        : 'ETP TEK-Torg - electronic trading platform for participation in procurement, tenders and auctions',
                description:
                    locale === 'ru'
                        ? 'Федеральная электронная торговая площадка для проведения государственных, коммерческих и малых закупок, имущественных торгов. Нам доверяют крупнейшие госкорпорации России!'
                        : 'Federal electronic trading platform for government and commercial procurement, small-volume purchases, sale of property. We are trusted by the largest state corporations of Russia!',
                keywords: ''
            },
            lead: pageData?.lead ?? null,
            newsCards: newsCards ?? null,
            ourCustomers: ourCustomers ?? null,
            ...(await serverSideTranslations(locale, ['common', 'index'])),
            initialReduxState: {
                searchProcedures: {
                    status: 'idle',
                    error: null,
                    data: [],
                    total: proceduresData?.total || null
                }
            }
        }
    };
};

export default Index;
